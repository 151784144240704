import { Select, SelectProps, styled } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

const StyledSelect = styled(Select)(({ theme }) => ({
  fieldset: {
    border: 'none',
  },
  '.MuiSelect-select': {
    backgroundColor: theme.palette.background.selectBackground,
  },
}));

export const SelectV2: React.FC<SelectProps> = ({
  children,
  ...selectProps
}) => {
  return (
    <StyledSelect
      size="small"
      IconComponent={ExpandMoreRoundedIcon}
      {...selectProps}
    >
      {children}
    </StyledSelect>
  );
};
