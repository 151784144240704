import { IPositionCandidate } from '@types';

export const CandidateListGroupingOptions: {
  label: string;
  value: keyof IPositionCandidate | 'position';
}[] = [
  { label: 'Positions', value: 'position' },
  { label: 'Seniority', value: 'seniority' },
  { label: 'Location', value: 'country' },
  { label: 'Status', value: 'status' },
  { label: 'Stack', value: 'skills' },
];
