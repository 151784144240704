import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useNavigate } from 'react-router-dom';

import {
  alpha,
  Box,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {
  CandidatesList,
  EmptyPositonsState,
  PageLoader,
  PositionsList,
  SelectV2,
} from '@components';

import { fetchPositions, posiitonsSelectors } from '@redux/positions';
import { isStatusLoading } from '@utils';
import {
  AppRoutes,
  CANDIDATES_LIST_GROUP_BY_LOCALSTORAGE_KEY,
} from '@constants';
import { useTitle } from '@hooks';
import { PageTitle } from 'constants/pageTitle';
import { LocalStorageService, logEvent } from '@services';
import { CandidateListGroupingOptions } from 'constants/candidates';
import { IPositionCandidate } from '@types';

export type CandidateGroupingOptionType =
  | keyof IPositionCandidate
  | 'position'
  | '';

export const CandidatesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setPageTitle = useTitle();

  const [groupingOption, setGroupingOption] =
    useState<CandidateGroupingOptionType>('');

  useEffect(() => {
    setPageTitle(PageTitle.CANDIDATES_PAGE);
  }, [setPageTitle]);

  const { apiStatus, data } = useAppSelector(
    posiitonsSelectors.getPositionsData,
  );

  useEffect(() => {
    dispatch(fetchPositions());
    const defaultGroupByOption = LocalStorageService.getState(
      CANDIDATES_LIST_GROUP_BY_LOCALSTORAGE_KEY,
    );
    if (defaultGroupByOption) {
      setGroupingOption(defaultGroupByOption as CandidateGroupingOptionType);
    }
  }, []);

  const onCandidateClick = useCallback((id: string) => {
    navigate(`/${AppRoutes.CANDIDATES}/${id}`);
    logEvent('candidate_info_review');
  }, []);

  const handleGroupingChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as CandidateGroupingOptionType;
    LocalStorageService.setState(
      CANDIDATES_LIST_GROUP_BY_LOCALSTORAGE_KEY,
      value,
    );
    setGroupingOption(value);
  };

  if (isStatusLoading(apiStatus)) {
    return <PageLoader />;
  }

  if (!data.length) {
    return <EmptyPositonsState />;
  }

  return (
    <Box sx={{ padding: '16px 24px 16px 16px', overflowY: 'auto' }}>
      <Paper sx={{ height: '100%' }}>
        <Stack gap={3}>
          <Stack direction="row" justifyContent="space-between" px={2} pt={3}>
            <Typography variant="h2">Candidates for your positions</Typography>
            <SelectV2
              displayEmpty
              value={groupingOption}
              onChange={handleGroupingChange}
              renderValue={(selectedValue) => {
                const selectedOption = CandidateListGroupingOptions.find(
                  (option) => option.value === selectedValue,
                );
                return (
                  <>
                    Group by:{' '}
                    {selectedOption ? selectedOption.label : 'No grouping'}
                  </>
                );
              }}
            >
              {CandidateListGroupingOptions.map((option) => (
                <MenuItem value={option.value}>{option.label}</MenuItem>
              ))}
              <MenuItem
                value=""
                sx={(theme) => ({
                  borderTop: `1px solid ${alpha(theme.palette.common.black, 0.25)}`,
                })}
              >
                No grouping
              </MenuItem>
            </SelectV2>
          </Stack>
          <CandidatesList
            data={data}
            groupingOption={groupingOption}
            onCandidateClick={onCandidateClick}
          />
          <PositionsList data={data} />
        </Stack>
      </Paper>
    </Box>
  );
};
