import React, { useMemo } from 'react';
import { groupBy, isEmpty, uniqBy } from 'lodash';

import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CountryFlag } from 'components';

import { CandidateListAction } from './components/CandidateListAction';
import { CandidateStatusInfo } from './components/CandidateStatusInfo';
import { IPositionCandidate, IPositionWithCandidates } from '@types';
import { pluralize } from 'utils/string';
import { CandidateGroupingOptionType } from '@pages';
import { CandidateListingStatusLabel, CandidateStatus } from '@constants';

const tableHeaders = [
  { label: 'Position / Candidate', width: '20%', align: 'left' },
  { label: 'Seniority', width: '9%', align: 'left' },
  { label: 'Main stack', width: '20%', align: 'left' },
  { label: 'Matching', width: '17%', align: 'left' },
  { label: 'Latest updates', width: '18%', align: 'left' },
  { label: 'Next steps', width: '16%', align: 'left' },
];

type CandidatesWithPosition = IPositionCandidate & { positionCount: number };
type GroupedCandidate = {
  name: string;
  additionalInfo: string;
  candidates: CandidatesWithPosition[];
};

export const CandidatesList: React.FC<{
  data: IPositionWithCandidates[];
  groupingOption: CandidateGroupingOptionType;
  onCandidateClick: (id: string) => void;
}> = ({ data, groupingOption, onCandidateClick }) => {
  const groupedData: GroupedCandidate[] = useMemo(() => {
    const allCandidates = data.flatMap(({ candidates }) => candidates);
    const uniqueCandidates = uniqBy(allCandidates, 'id').map((candidate) => ({
      ...candidate,
      positionCount: data.filter(({ candidates }) =>
        candidates.some(({ id }) => id === candidate.id),
      ).length,
    }));

    if (groupingOption === 'position') {
      return data.map(({ candidates, ...position }) => ({
        name: position.name,
        additionalInfo: position.seniorities.join(', '),
        candidates: candidates.map((candidate) => ({
          ...candidate,
          positionCount:
            uniqueCandidates.find(({ id }) => id === candidate.id)
              ?.positionCount ?? 0,
        })),
      }));
    }

    return Object.entries(groupBy(uniqueCandidates, groupingOption)).map(
      ([key, candidates]) => ({
        name:
          groupingOption === 'status'
            ? CandidateListingStatusLabel[key as CandidateStatus]
            : key,
        additionalInfo: '',
        candidates,
      }),
    );
  }, [data, groupingOption]);

  if (isEmpty(groupedData)) {
    return (
      <Stack
        gap="0.5rem"
        flexDirection="column"
        py="3rem"
        px="5rem"
        bgcolor="background.backgroundPrimary"
        alignItems="center"
        borderRadius="1rem"
        my="3.5rem"
        mx="auto"
      >
        <img
          src="https://ae-cp-static-files.s3.eu-central-1.amazonaws.com/client-dashboard/team.png"
          alt="team"
          width="172"
          height="172"
        />
        <Typography variant="h2" textAlign="center">
          We’re pre-screening candidates for you
        </Typography>
        <Typography variant="h5" color="text.secondary" textAlign="center">
          Please expect an email from us once we have suitable matches
        </Typography>
      </Stack>
    );
  }

  return (
    <TableContainer>
      <Table stickyHeader sx={{ tableLayout: 'auto', width: '100%' }}>
        <TableHead>
          <TableRow>
            {tableHeaders.map(({ label, width, align }, idx) => (
              <TableCell
                key={idx}
                sx={(theme) => ({
                  padding: '0.4rem 0 0 1rem',
                  width,
                  height: '2rem',
                  verticalAlign: 'top',
                  border: 'none',
                  textAlign: align,
                  color: theme.palette.text.secondary,
                })}
              >
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedData.map((group, index) => (
            <React.Fragment key={index}>
              {groupingOption && (
                <TableRow
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.background.inputDisabled,
                  }}
                >
                  <TableCell
                    colSpan={6}
                    sx={{
                      padding: '8px 16px',
                      borderTop:
                        index === 0
                          ? '1px solid rgba(224, 224, 224, 1)'
                          : 'none',
                    }}
                  >
                    <Typography component="span" variant="body1">
                      {group.name.split(',').join(', ')}
                    </Typography>
                    {group.additionalInfo && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="secondary"
                        marginLeft="1rem"
                      >
                        {group.additionalInfo}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
              {!group.candidates.length ? (
                <TableRow>
                  <TableCell colSpan={6} sx={{ padding: '8px 16px' }}>
                    No candidates
                  </TableCell>
                </TableRow>
              ) : (
                group.candidates.map((candidate) => (
                  <TableRow
                    key={candidate.id}
                    onClick={() => onCandidateClick(candidate.id)}
                    sx={(theme) => ({
                      cursor: 'pointer',
                      '&:hover': {
                        background: theme.palette.highlight.actionable,
                      },
                    })}
                  >
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <CountryFlag
                          country={candidate.country}
                          sx={{ mr: 1 }}
                        />
                        <Typography
                          variant="body1"
                          color="text.link"
                          sx={{ textDecoration: 'underline' }}
                        >
                          {`${candidate.firstName} ${candidate.lastName}`}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {candidate.seniority}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" gap={0.5} flexWrap="wrap">
                        {candidate.skills?.map((skill) => (
                          <Box
                            key={skill}
                            component="span"
                            sx={(theme) => ({
                              background:
                                theme.palette.background.backgroundPrimary,
                              padding: '4px 8px 6px 8px',
                              borderRadius: '4px',
                              textWrap: 'nowrap',
                              fontSize: '0.825rem',
                            })}
                          >
                            {skill}
                          </Box>
                        ))}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1" color="text.secondary">
                        {pluralize(candidate.positionCount, 'position')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <CandidateStatusInfo candidate={candidate} />
                    </TableCell>
                    <TableCell>
                      <CandidateListAction candidate={candidate} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
