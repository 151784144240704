import { useState } from 'react';
import { isNumber } from 'lodash';
import { Box, IconButton, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { ICandidateDetails } from '@types';
import { videoInterviewFileUrl } from '@constants';
import { PlayArrow } from '@mui/icons-material';
import { logEvent } from '@services';

export const CandidateDetailsVideoInterview: React.FC<{
  data: ICandidateDetails;
  hideQuestions: boolean;
}> = ({ data, hideQuestions }) => {
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const questions = data.videoInterview;

  if (!questions || !questions.length) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        sx={{
          borderRadius: '10px',
          overflow: 'hidden',
          background: 'black',
          width: { xs: '100%', md: '100%' },
          height: { xs: '40%', md: '289px' },
        }}
      >
        <ReactPlayer
          controls
          playing={isPlaying}
          width="100%"
          height="100%"
          url={
            isNumber(selectedQuestion)
              ? videoInterviewFileUrl(
                  questions[selectedQuestion].recordFileName!,
                )
              : videoInterviewFileUrl(questions[0].recordFileName!)
          }
          style={{
            background: 'black',
            borderRadius: '4px',
          }}
        />
        {!isNumber(selectedQuestion) && !hideQuestions && (
          <Typography variant="body2" color="text.invertedSecondary">
            Please select a question to play
          </Typography>
        )}

        {/* Play Button */}
        {!isPlaying && (
          <IconButton
            onClick={() => {
              setIsPlaying(true);
              logEvent('submission_vi_click');
            }}
            sx={{
              position: 'absolute',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              borderRadius: '50%',
              p: 2,
            }}
          >
            <PlayArrow fontSize="large" />
          </IconButton>
        )}
      </Box>

      {!hideQuestions && (
        <Box display="flex" flexDirection="column" gap={0.5}>
          {questions.map((question, idx) => {
            const hasRecord = !!question.recordFileName;

            return (
              <Box
                key={question.recordFileName}
                onClick={() => hasRecord && setSelectedQuestion(idx)}
                sx={(theme) => ({
                  padding: '8px 16px 16px 16px',
                  cursor: hasRecord ? 'pointer' : 'default',
                  borderRadius: '4px',
                  background:
                    selectedQuestion === idx
                      ? theme.palette.highlight.accent
                      : hasRecord
                        ? theme.palette.highlight.actionable
                        : theme.palette.highlight.neutral,
                })}
              >
                <Typography variant="body2" color="text.secondary">
                  Question {idx + 1}
                </Typography>
                <Typography variant="body1">{question.question}</Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
